/*
  DO NOT add or edit this global css styles. You really need a very good reason to do this as jss-as-css is more versatile
  and ditches the need to use scss / less. EL
 */

html {
  height: 100%;
  font-size: 16px;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "OpenSans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Light'), url(./fonts/OpenSans-Light.ttf) format('truetype');
  font-weight: 400
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Bold'), url(./fonts/OpenSans-Bold.ttf) format('truetype');
  font-weight: 700
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-ExtraBold'), url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
  font-weight: 800
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Regular'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: 600
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-LightItalic'), url(./fonts/OpenSans-LightItalic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-BoldItalic'), url(./fonts/OpenSans-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-ExtraBoldItalic'), url(./fonts/OpenSans-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans-Italic'), url(./fonts/OpenSans-Italic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

.grecaptcha-badge{display:none;}
